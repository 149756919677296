<template>
  <!-- 人脸识别 -->
  <a-modal
    v-model:visible="visible"
    :width="600"
    :footer="null"
    centered
    :closable="false"
    :maskClosable="false"
    :keyboard="false"
  >
    <face-container>
      <face-index ref="faceIndexRef" @on-send="handleSend"></face-index>
      <template #action>
        <div class="action-tip">
          <a-alert
            v-if="state.matchStatus == 1"
            :message="$t('MatchSuccessful')"
            type="success"
            show-icon
          />
          <a-alert
            v-if="state.matchStatus == 2"
            :message="$t('MatchFail')"
            type="error"
            show-icon
          />
          <a-alert
            v-if="state.matchStatus == 3"
            :message="$t('matchStatusTip3')"
            type="error"
            show-icon
          />
          <a-alert
            v-if="state.matchStatus == 4"
            :message="
              $t('matchStatusTip4', { n: centerNum - state.centerFailures })
            "
            type="error"
            show-icon
          />
        </div>
        <!-- 开始匹配 -->
        <!-- <a-button
          block
          size="large"
          type="primary"
          @click="start"
          v-if="state.status === 1"
          >{{ $t("StartMatching") }}30S</a-button
        > -->
        <!-- 匹配中 -->
        <a-button
          v-if="state.status === 2"
          block
          size="large"
          type="primary"
          :loading="loading || submitLoading"
          readonly
          >{{ $t("Matching") }}...{{ state.timer.num }}S</a-button
        >
        <!-- 重新匹配 -->
        <!-- <a-button
          block
          size="large"
          type="primary"
          v-else-if="state.status === 3"
          @click="start"
          >{{ $t("Rematch") }}30S</a-button
        > -->
      </template>
    </face-container>
    <div class="face-tip">
      <a-divider>
        <!-- 头像采集提醒 -->
        <span class="tip-title">{{ $t("AvatarCaptureAlert") }}</span>
      </a-divider>
      <div class="tip-item">1.{{ $t("AvatarCaptureAlertTip1") }}</div>
      <div class="tip-item">2.{{ $t("AvatarCaptureAlertTip2") }}</div>
      <div class="tip-item">3.{{ $t("AvatarCaptureAlertTip6") }}</div>
      <div class="tip-item" v-if="config.customerCode === 'dfzx'">4.{{ $t("AvatarCaptureAlertTip7") }}</div>
    </div>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance, computed, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { submitFace, matchFace, userInfo } from "@/api/user";
import faceContainer from "./container.vue";
import faceIndex from "./index.vue";
import { Modal } from "ant-design-vue";
import ls from "@/utils/local-storage";
import { debounce } from "lodash";
const props = defineProps({
  // 进行中识别次数
  centerNum: {
    type: Number,
    default: 1,
  },
  id: {
    type: Number,
    default: 0,
  },
  // 识别失败 自动返回
  errAutoBack: {
    type: Boolean,
    default: true,
  },
  taskType: {
    type: Number,
    default: 2,
  },
});
const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const router = useRouter();
const store = useStore();
const config = computed(() => store.getters.platformConfig);
const visible = ref(false);
const faceIndexRef = ref(null);

const state = reactive({
  status: 1, // 1开始 2匹配中 3重新
  loading: false,
  submitLoading: false,
  timer: {
    ref: null,
    num: 30,
  },
  faceType: 1, // 1前 2完成 >3中
  centerFailures: 0, // 中失败次数
  matchStatus: 0, // 1 成功 2失败 3失败-倒计时未完 4 失败-失败次数
  timerSubmit: false, // 倒计时提交 默认失败
});
async function checkFace() {
  let openfaceStatus = 0;
  let faceImagePath = "";
  let flag = false;
  const res = await userInfo();
  if (res.ret === 0) {
    store.dispatch("setUserInfo", res.data);
  }
  const user = res.data || null;
  if (user) {
    openfaceStatus = user.openfaceStatus;
    faceImagePath = user.faceImagePath;
  }

  if (!faceImagePath || [2, 4].includes(openfaceStatus)) {
    flag = false;
    //openfaceStatus 1导入已完成 2未审核 3审核成功 4审核驳回
    // 您暂未采集人脸数据？
    let tip = $t("YouHaveNotCollectedFaceDataYet");
    let btnStr = $t("GoSample");
    if (openfaceStatus == 4) {
      // 人脸数据审核不通过。
      tip = $t("FailedFaceDataAudit");
    }
    if (openfaceStatus == 2) {
      // 人脸数据审核中。
      tip = $t("FaceDataReviewInProgress");
      btnStr = $t("CM_Confirm");
    }
    if (config.value.customerCode === "dfzx") {
      tip = $t("PleaseLogInToTheExpertPoolSystemToUpdateYourPhotoInformation");
      btnStr = $t("CM_Confirm");
    }
    Modal.warning({
      title: () => $t("XB_WarmTip"),
      // 温馨提示
      content: () => tip,
      // 您暂未采集人脸数据？
      okText: () => btnStr,
      // 去采样
      keyboard: false,
      centered: true,
      zIndex: 1011,
      onOk: () => {
        if (openfaceStatus == 2 || config.value.customerCode === "dfzx") {
          router.go(-1);
        } else {
          router.push({
            path: "/mine/study/profile",
            query: {
              tab: 3,
            },
          });
        }
      },
    });
  } else {
    flag = true;
  }
  return flag;
}
async function show(type) {
  state.faceType = type;
  state.matchStatus = 0;
  state.status = 1;
  state.centerFailures = 0;
  state.submitLoading = false;
  visible.value = await checkFace();
  if (visible.value) start();
}

async function start() {
  // 匹配前也需验证 人脸是否正常
  // const isOk = await checkFace();
  if (state.loading || state.submitLoading) return false;
  state.matchStatus = 0;
  state.timerSubmit = false;
  nextTick(() => {
    faceIndexRef.value.openCamera();
    initTimer();
    state.status = 2;
  }, 1000);
}
function initTimer() {
  if (state.timer.ref) return false;
  state.timer.ref = setInterval(() => {
    if (state.timer.num == 0) {
      clearTimer();
      // 30S后还没有通过，随机抓取一张传给后台
      state.timerSubmit = true;
      faceIndexRef.value.getPhoto(matchPhoto);
    } else {
      state.timer.num -= 1;
    }
  }, 1000);
}
function clearTimer() {
  clearInterval(state.timer.ref);
  state.timer.ref = null;
  state.timer.num = 30;
  state.status = 3;
  faceIndexRef.value.stopCamera();
}

async function matchPhoto(formData) {
  if (state.loading || state.submitLoading) return false;
  state.loading = true;
  const res = await matchFace(formData);
  if (res.ret == 0) {
    if (state.faceType > 3) state.faceType += 1;
    state.submitLoading = true;
    if (state.timerSubmit) res.data.code = 2;
    submitPhoto(res.data);
  }
  state.loading = false;
  // state.status = 3;
}
const submitPhoto = debounce((data) => {
  // 识别成功
  if (data.code === 1) {
    // 成功
    state.matchStatus = 1;
    proxy.$message.success($t("MatchSuccessful"));
    setTimeout(() => {
      emit("on-success", state.faceType);
      visible.value = false;
    }, 200);
  } else {
    // 失败后 倒计时未结束 继续识别
    if (state.timer.num > 0 && state.timer.num != 30) {
      // proxy.$message.error($t('MatchFail'));
      state.matchStatus = 3;
      state.submitLoading = false;
      faceIndexRef.value.clearFace();
      return false;
    }
    // 连续失败次数未达到 继续识别
    state.centerFailures += 1;
    if (state.faceType >= 3 && state.centerFailures < props.centerNum) {
      // proxy.$message.error($t('MatchFail'));
      state.matchStatus = 4;
      state.status = 3;
      state.submitLoading = false;
    } else {
      state.matchStatus = 2;
      if (props.errAutoBack) {
        if (state.faceType == 1) {
          // 人脸识别不通过，请确定是否是本人或者重新采集照片
          visible.value = false;
          Modal.warning({
            title: () => $t("XB_WarmTip"),
            // 温馨提示
            content: () =>
              $t(
                config.value.customerCode === "dfzx"
                  ? "FaceMatchErrorTip3"
                  : "FaceMatchErrorTip"
              ),
            okText: () => $t("CM_Confirm"),
            keyboard: false,
            centered: true,
            onOk: () => {
              router.go(-1);
            },
          });
        } else {
          proxy.$message.error($t("MatchFail"));
          setTimeout(() => {
            router.go(-1);
          }, 200);
        }
      }
    }
  }
  faceIndexRef.value.stopCamera();
  emit("on-result", data);
  // 考试前 因没有submitId 不走提交接口 单独处理
  if (!props.id) {
    visible.value = false;
    clearTimer();
    return;
  }
  submitResult(data);
  clearTimer();
}, 300);
async function submitResult(data) {
  const p = {
    taskcount: state.faceType, // 1前 2完成 >3中
    tasktype: props.taskType, // 1考试 2课程
    id: props.id,
    ...data,
  };
  state.submitLoading = true;
  const res = await submitFace(p);
  // state.submitLoading = false;
}
function handleSend(val) {
  if (val && !state.loading && !state.submitLoading) {
    faceIndexRef.value.getPhoto(matchPhoto);
  }
}
defineExpose({
  checkFace,
  show,
  submitResult,
});
const emit = defineEmits(["emit"]);
</script>
<style lang="less" scoped>
.action-tip {
  margin-bottom: 5px;
}
.face-tip {
  .tip-title {
    font-size: 14px;
    font-weight: 700;
    color: #333;
  }
  .tip-item {
    margin: 5px 0;
    font-size: 12px;
    color: #666;
  }
}
</style>
